import {Pipe, PipeTransform} from "@angular/core";
import moment from 'moment';
import {Moment} from "moment";

@Pipe({
  name: 'bookingDuration',
  standalone: true,
})
export class BookingDurationPipe implements PipeTransform {

  public transform(start: Moment, end: Moment): string {
    const difference = end.diff(start) ?? 0;
    const duration = moment.duration(difference);

    return `${duration.asMinutes().toFixed(0)} min.`;
  }

}
