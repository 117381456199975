import { Action } from '@ngrx/store';
import { PaginationHolder } from '@models/pagination.model';
import { ICategoryModel } from '@models/category.model';
import { ICreateCategoryRequestModel } from '@models/request/create-category.request.model';
import { ICreateProductRequestModel, IProductModel, IUpdateProductRequestModel } from '@models/product.model';
import { IUpdateCategoryRequestModel } from '@models/request/update-category.request.model';

export enum ECatalogActions {
  FetchCategories = '[CATALOG] Fetch Categories',
  FetchCategoriesSuccess = '[CATALOG] Fetch Categories Success',

  CreateCategory = '[CATALOG] Create Category',
  CreateCategorySuccess = '[CATALOG] Create Category Success',

  UpdateCategoryWithImage = '[CATALOG] Update Category With Image',
  UpdateCategoryWithImageSuccess = '[CATALOG] Update Category With Image Success',

  UpdateCategory = '[CATALOG] Update Category',
  UpdateCategorySuccess = '[CATALOG] Update Category Success',

  DeleteCategory = '[CATALOG] Delete Category',
  DeleteCategorySuccess = '[CATALOG] Delete Category Success',

  SortCategories = '[CATALOG] Sort Categories',
  SortCategoriesSuccess = '[CATALOG] Sort Categories Success',

  FetchProducts = '[CATALOG] Fetch Products',
  FetchProductsSuccess = '[CATALOG] Fetch Products Success',

  FetchCategoryProducts = '[CATALOG] Fetch Category Products',
  FetchCategoryProductsSuccess = '[CATALOG] Fetch Category Products Success',

  CreateProduct = '[CATALOG] Create Product',
  CreateProductSuccess = '[CATALOG] Create Product Success',

  UpdateProduct = '[CATALOG] Update Product',
  UpdateProductSuccess = '[CATALOG] Update Product Success',

  DeleteProduct = '[CATALOG] Delete Product',
  DeleteProductSuccess = '[CATALOG] Delete Product Success',

  SetSelectedCategoryId = '[CATALOG] Set Selected Category Id',
}

// Fetch Categories

export class FetchCategories implements Action {
  public readonly type = ECatalogActions.FetchCategories;

  constructor() {}
}

export class FetchCategoriesSuccess implements Action {
  public readonly type = ECatalogActions.FetchCategoriesSuccess;

  constructor(public categories: PaginationHolder<ICategoryModel>) {}
}

// Create Category

export class CreateCategory implements Action {
  public readonly type = ECatalogActions.CreateCategory;

  constructor(
    public reqModel: ICreateCategoryRequestModel,
    public file?: File
  ) {}
}

export class CreateCategorySuccess implements Action {
  public readonly type = ECatalogActions.CreateCategorySuccess;

  constructor() {}
}

// Update Category With Image

export class UpdateCategoryWithImage implements Action {
  public readonly type = ECatalogActions.UpdateCategoryWithImage;

  constructor(
    public id: string,
    public reqModel: IUpdateCategoryRequestModel,
    public file: File
  ) {}
}

export class UpdateCategoryWithImageSuccess implements Action {
  public readonly type = ECatalogActions.UpdateCategoryWithImageSuccess;

  constructor() {}
}

// Update Category

export class UpdateCategory implements Action {
  public readonly type = ECatalogActions.UpdateCategory;

  constructor(
    public id: string,
    public reqModel: IUpdateCategoryRequestModel
  ) {}
}

export class UpdateCategorySuccess implements Action {
  public readonly type = ECatalogActions.UpdateCategorySuccess;

  constructor() {}
}

// Delete Category

export class DeleteCategory implements Action {
  public readonly type = ECatalogActions.DeleteCategory;

  constructor(public id: string) {}
}

export class DeleteCategorySuccess implements Action {
  public readonly type = ECatalogActions.DeleteCategorySuccess;

  constructor() {}
}

// Sort Categories

export class SortCategories implements Action {
  public readonly type = ECatalogActions.SortCategories;

  constructor(public categories: { id: string; index: number }[]) {}
}

export class SortCategoriesSuccess implements Action {
  public readonly type = ECatalogActions.SortCategoriesSuccess;

  constructor() {}
}

// Fetch Products

export class FetchProducts implements Action {
  public readonly type = ECatalogActions.FetchProducts;

  constructor(public fetch_type?: string) {}
}

export class FetchProductsSuccess implements Action {
  public readonly type = ECatalogActions.FetchProductsSuccess;

  constructor(public products: PaginationHolder<IProductModel>) {}
}

// Fetch Category Products

export class FetchCategoryProducts implements Action {
  public readonly type = ECatalogActions.FetchCategoryProducts;

  constructor(public category_id: string) {}
}

export class FetchCategoryProductsSuccess implements Action {
  public readonly type = ECatalogActions.FetchCategoryProductsSuccess;

  constructor(public products: PaginationHolder<IProductModel>) {}
}

// Create Product

export class CreateProduct implements Action {
  public readonly type = ECatalogActions.CreateProduct;

  constructor(
    public reqModel: ICreateProductRequestModel,
    public categories: ICategoryModel[]
  ) {}
}

export class CreateProductSuccess implements Action {
  public readonly type = ECatalogActions.CreateProductSuccess;

  constructor() {}
}

// Update Product

export class UpdateProduct implements Action {
  public readonly type = ECatalogActions.UpdateProduct;

  constructor(
    public reqModel: IUpdateProductRequestModel,
    public id: string,
    public previousCategories: ICategoryModel[],
    public newCategories: ICategoryModel[]
  ) {}
}

export class UpdateProductSuccess implements Action {
  public readonly type = ECatalogActions.UpdateProductSuccess;

  constructor() {}
}

// Delete Product

export class DeleteProduct implements Action {
  public readonly type = ECatalogActions.DeleteProduct;

  constructor(public id: string) {}
}

export class DeleteProductSuccess implements Action {
  public readonly type = ECatalogActions.DeleteProductSuccess;

  constructor() {}
}

// Set Selected Category ID

export class SetSelectedCategoryId implements Action {
  public readonly type = ECatalogActions.SetSelectedCategoryId;

  constructor(public id: string | undefined) {}
}

// Types

export type CatalogActions =
  | FetchCategories
  | FetchCategoriesSuccess
  | CreateCategory
  | CreateCategorySuccess
  | UpdateCategoryWithImage
  | UpdateCategoryWithImageSuccess
  | UpdateCategory
  | UpdateCategorySuccess
  | DeleteCategory
  | DeleteCategorySuccess
  | FetchProducts
  | FetchProductsSuccess
  | FetchCategoryProducts
  | FetchCategoryProductsSuccess
  | CreateProduct
  | CreateProductSuccess
  | UpdateProduct
  | UpdateProductSuccess
  | DeleteProduct
  | DeleteProductSuccess
  | SetSelectedCategoryId;
